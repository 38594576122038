.site-header {
  height: 160px;
  padding-left: 50px;
  position: relative;
  background: #f7f7f9;
  display: table;

  .site-header__valign {
    display: table-cell;
    vertical-align: middle;
  }
}