[class^="icon-computer-accept"],
[class*="icon-computer-accept"],
[class^="icon-rocket"],
[class*="icon-rocket"] {
  height: 100px;
  width: 100px;
  display: inline-block;
  fill: currentColor;
}

[class^="icon-computer-accept"],
[class*="icon-computer-accept"] {
  color: #de6b59;
}

[class^="icon-rocket"],
[class*="icon-rocket"] {
  color: #67ac5b;
}

[class^="icon-envelope"],
[class*="icon-envelope"],
[class^="icon-phone-1"],
[class*="icon-phone-1"],
[class^="icon-at"],
[class*="icon-at"] {
  color: #fff;
  height: 50px;
  width: 50px;
  fill: currentColor;
}

[class^="icon-phone-1"],
[class*="icon-phone-1"],
[class^="icon-at"],
[class*="icon-at"] {
  height: 40px;
  margin-bottom: 6px;
}

[class^="icon-phone-1"],
[class*="icon-phone-1"] {
  margin-right: 50px;
}

[class^="icon-envelope"],
[class*="icon-envelope"] {
  margin-right: 59px;
}

.icon_frontpage {
  height: 125px;
}

