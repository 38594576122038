.content-text {
  color: #84859d;
  font-family: "Raleway",Helvetica,Arial,sans-serif;
  font-size: .92857rem;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: .65px;

  h2 {
    font-size: 2.14286rem;
    font-weight: 300;
    line-height: 1.27;
    letter-spacing: .6px;
  }

  h3 {
    font-size: 2.14286rem;
    font-weight: 300;
    line-height: 1.27;
    letter-spacing: .6px;
  }

  p {
    margin: 0 0 1.57143rem 0;
  }

  ul {
    padding-left: 25px;
  }

  .bold {
    font-weight: 700;
  }

  .accent {
    color: #f27e20;
  }

  .portrets {
    text-align: center;
    margin-bottom: 30px;

    .portrets__portret {
      display: inline-block;
      vertical-align: top;
      padding: 20px;

      .image {
        margin-bottom: 20px;

        >img {
          border: 3px solid #fac192;
          border-radius: 50%;
          overflow: hidden;
        }
      }

      .portrets__title {
        color: #f6a665;
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 0;
      }
    }
  }
}

.content-text h1, .content-text h2, .content-text h3, .content-text h4, .content-text h5, .content-text h6, .accent {
  color: #f27e20;
}

.software {
  color: #de6b59;

  h3 {
    color: #de6b59;
  }

  .accent {
    color: #de6b59;
  }
}

.participatie {
  color: #4ba7ee;

  h3 {
    color: #4ba7ee;
  }

  .accent {
    color: #4ba7ee;
  }
}

.startups {
  color: #67ac5b;

  h3 {
    color: #67ac5b;
  }

  .accent {
    color: #67ac5b;
  }
}

.bg-white {
  background-color: #fff;
}