.site-nav {
  height: 70px;
  position: relative;
  text-align: center;
  z-index: 5;

  .site-nav__list-container {
    text-align: left;
  }

  .site-nav__list {
    display: inline-block;
    height: 70px;
    margin: 0;
    padding-left: 32px;
  }

  .site-nav__list > li {
    list-style: none;
  }

  .site-nav__list-item {
    height: 70px;
    float: left;
  }

  .site-nav__link {
    height: 70px;
    display: block;
    color: #84859d;
    font-size: 1.07143rem;
    padding-top: 25px;
    padding-right: 22px;
    padding-left: 22px;
    letter-spacing: .5px;
    border-bottom: solid 3px transparent;
    transition: background-color .5s, color .5s;

    &.-tel-no {
      padding-right: 32px;
      padding-left: 32px;
      position: absolute;
      top: 0;
      right: 22px;
    }
  }

  .site-nav__list-item:hover .site-nav__link, .site-nav__list-item.-selected .site-nav__link {
    border-color: #fac192;
    color: #f27e20;
    background-color: #f7f7f9;
    text-decoration: none;
  }

  .site-nav__list-item:hover .site-nav__link.software, .site-nav__list-item.-selected .site-nav__link.software { border-color: #e3887a; text-decoration: none; color: #de6b59; }
  .site-nav__list-item:hover .site-nav__link.participatie, .site-nav__list-item.-selected .site-nav__link.participatie { border-color: #6ba1ee; text-decoration: none; color: #4ba7ee; }
  .site-nav__list-item:hover .site-nav__link.startups, .site-nav__list-item.-selected .site-nav__link.startups { border-color: #6caa5f; text-decoration: none; color: #67ac5b; }
}