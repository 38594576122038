.zig-zag-content__container {
  padding: 7.14286% 10%;
}

.zig-zag__column-holder {
  display: table;
  width: 100%;
  table-layout: fixed;

  &.-ltr {
    direction: rtl;
  }
}

.zig-zag__column {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
  direction: ltr;

  &.-left {
    padding-right: 5%;
  }

  &.-right {
    padding-left: 5%;
  }

  &.-image {
    margin-bottom: 0;
    text-align: center;

    img {
      &#software_1 {
        max-width: 335px;
        width: 100%;
      }

      &#software_2 {
        max-width: 500px;
        width: 100%;
      }

      &#participatie_1 {
        max-width: 300px;
        width: 100%;
      }

      &#participatie_2 {
        max-width: 500px;
        width: 100%;
      }

      &#startups {
        max-width: 260px;
        width: 100%;
      }
    }
  }
}

.zig-zag__image {
  margin: 0 auto;
}

.zig-zag__content-text {
  font-size: 1.07143rem;
  line-height: 1.66667;
  letter-spacing: .05em;

  &.-margin-bottom {
    margin-bottom: 40px;
  }
}