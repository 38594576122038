@import "normalize";
@import "icons";
@import "content";

html {
  background-color: #e5e5e5;
  font: 0.875em/1.57143 "Raleway",Helvetica,Arial,sans-serif;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*, *:after, *:before {
  box-sizing: border-box;
}

body {
  color: #84859d;
  background-color: #f7f7f9;
  font-family: "Raleway",Helvetica,Arial,sans-serif;
  font-weight: 500;
  font-size: 1rem;
  font-feature-settings: 'liga' 0;
  width: 100%;
}

a {
  background-color: transparent;
  color: #84859d;
  text-decoration: none;
  outline: 0;
}

a:hover {
  text-decoration: underline;
}

#menu-button {
  display: none;
}

.site-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1480px;
  overflow: hidden;
  padding-bottom: 45px;
  padding-left: 40px;
  padding-right: 40px;
}

.btn {
  line-height: 1;
  text-align: center;
  display: inline-block;
  font-size: .92857rem;
  color: #84859d;
  white-space: nowrap;
  vertical-align: top;
  transition: all .2s;


  &.-outline {
    text-transform: uppercase;
    border: solid 2px #fac192;

    &:hover {
      outline: 0;
      background-color: #fac192;
      color: #fff;
      text-decoration: none;
    }

    &.-outline-sector-software {
      border-color: #DE9F8C;

      &:hover {
        background-color: #DE9F8C;
      }
    }

    &.-outline-sector-participatie {
      border-color: #92BBEE;

      &:hover {
        background-color: #92BBEE;
      }
    }

    &.-outline-sector-startups {
      border-color: #8EAC8D;

      &:hover {
        background-color: #8EAC8D;
      }
    }

    &.-outline-sector-bedankt {
      color: #fff;
      border-color: #fff;
    }
  }

  &.-large {
    font-weight: 700;
    letter-spacing: 1.3px;
    border-radius: 25px;
    padding:18px 32px;
  }
}

.site-logo {
  display: inline-block;
  height: 45px;
  z-index: 7;

  img {
    display: block;
    height: 45px;
  }
}

.table-area {
  width: 100%;
  display: table;
}

@import "site-header";

.site-main {
  background-color: #fff;
  box-shadow: 0 0 50px 17px rgba(225,226,228,0.7);
  position: relative;
}

@import "site-nav";
@import "feature-box";
@import "col-grid";
@import "zig-zag";
@import "full-width-content";
@import "form";
@import "contact";

@import "footer";

.site-section {
  width: 100%;

  &.section-push {
    margin-bottom: 23px;
  }

  &.-last-white {
    height: 50px;
  }

  &.-single {
    background-color: #fff;
    padding-bottom: 75px;
  }

  &.-light-gray {
    background-color: #f7f7f9;
  }
}

@import "responsive";