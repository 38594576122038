.feature-box-container {
  display: table;
  float: none;
  width: 100%;
  white-space: nowrap;
  position: relative;
  z-index: 4;
}

.feature-box {
  background: center top;
  background-size: cover;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 100%;
  height: 450px;

  &.-home {
    height: 424px;
    background-color: #84859b;
    padding-bottom: 30px;

    h1 {
      font-weight: 300;
    }
  }

  &.-regular {
    height: 200px;
    background-color: #84859b;
  }

  &.-thanks-message {
    background: url("/img/care4software_logo_background-purple.png") no-repeat center center;
    height: 700px;
    vertical-align: 0;
    padding-top: 150px;
  }

  &.software {
    background-color: #e3887a;
  }

  &.participatie {
    background-color: #6ba1ee;
  }

  &.startups {
    background-color: #6caa5f;
  }

  &.-split-3 {
    width: 33%;
    display: table-cell;
    vertical-align: middle;
    position: relative;
    border-spacing: 0;
  }

  &.-expand-hover {
    transition: all .2s;
  }

  h1, p {
    color: #fff;
  }

  h1 {
    font-size: 2.85714rem;
    font-weight: 400;
    line-height: 1.38;
    letter-spacing: 1px;
  }

  h2 {
    font-size: 1.78571rem;
    font-weight: 400;
    margin-bottom: .36em;
  }

  p {
    font-size: 1.21429rem;
    letter-spacing: 1.35px;
  }

  .btn {
    margin-top: 2em;
  }

  &.-expand-hover {

    &:hover {
      z-index: 4;


      &:before {
        transform: scaleY(1.08);
      }

      &:after {
        opacity: 1;
        -ms-transform: scaleY(1.08);
        transform: scaleY(1.08);
        height: inherit;
      }
    }

    &:before {
      background-color: #fff;
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height: inherit;
      z-index: 0;
      transition: all .2s;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      box-shadow: 0 0 20px 3px rgba(230,231,233,0.5);
      opacity: 0;
      transition: all .2s;
    }
  }
}

.feature-box__valign {
  vertical-align: middle;
}

.feature-box-inner {
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  max-width: 785px;
  padding-right: 20px;
  padding-left: 20px;
  position: relative;
  vertical-align: middle;
  white-space: normal;
  z-index: 3;
}

.feature-box__content {
  width: 77%;
  margin-right: auto;
  margin-left: auto;
  white-space: normal;
  position: relative;
  z-index: 1;
}

.feature-box__illustration {
  margin-bottom: 25px;
}

