.-contact-grid {
  letter-spacing: .03571rem;
  font-size: 1.07143rem;

  &.-info {
    color: #fff;
    background-color: #a7a7b3;

    a {
      color: #fff;
    }

    h2 {
      color: #fff;
    }

    h3 {
      line-height: 23px;
    }

    p {
      line-height: 23px;
    }
  }

  h2 {
    margin: 0 0 1.07143rem 0;
  }

  h3 {
    color: #fff;
    margin: 0;
    font-size: 1.07143rem;
    font-weight: 700;
  }

  .contact__icon-holder {
    display: block;
    margin-top: 225px;
    text-align: center;
  }
}