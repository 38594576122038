@media screen and (max-width: 980px) {
  .site-nav .site-nav__link.-tel-no {
    display: none;
  }

  .-table-area__reverse {
    display: flex;
    flex-direction: column-reverse;
  }

  .col-2-grid {
    display: block;
    width: 100%;
  }

  .site-form .input.text input, .site-form .input.textarea textarea, .site-form .input.submit,
  .contact-form .g-recaptcha {
    width: 100%;
  }

  .-contact-grid .contact__icon-holder {
    margin-top: 45px;

    &[class^="icon-envelope"], [class*="icon-envelope"] {
      margin-right: 49px;
    }

    &[class^="icon-phone-1"], [class*="icon-phone-1"] {
      margin-right: 40px;
    }
  }
}

@media screen and (max-width: 900px) {
  .feature-box h2 {
    font-size: 1.38571rem;
  }

  [class^="icon-computer-accept"], [class*="icon-computer-accept"], [class^="icon-rocket"], [class*="icon-rocket"] {
    height: 80px;
    width: 80px;
  }

  .btn.-large {
    padding: 13px 22px;
  }

  .icon_frontpage {
    height: 80px;
  }

  .zig-zag__column {
    display: block;
    width: 100%;
    padding: 0;

    &.-right {
      padding: 0;
    }
  }
}

@media screen and (max-width: 790px) {
  .site-nav .site-nav__link {
    font-size: 1rem;
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (max-width: 745px) {
  .site-footer__top .site-footer__column.-float-right .site-logo,
  .site-footer__top .site-footer__column.-float-right .site-logo img {
    height: 33px;
  }
}

@media screen and (max-width: 720px) {
  .site-container {
    padding-left: 0;
    padding-right: 0;
  }

  .feature-box {
    height: 320px;
  }

  .feature-box.-split-3 {
    width: 100%;
    display: block;
    float: left;
  }

  .feature-box__content {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .site-header {
    width: 100%;
    height: 53px;
    margin-top: 0;
    padding: 0;
    z-index: 1;

    .site-logo {
      height: 40px;
      margin-top: 13px;
      position: absolute;
      top: 0;
      left: 20px;

      img {
        height: 26px;
      }
    }
  }

  #menu-button {
    display: block;
    height: 53px;
    padding-top: 10px;
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;

    [class^="icon-bars"], [class*="icon-bars"] {
      color: #f38a36;
      height: 32px;
      width: 32px;
      display: inline-block;
      fill: currentColor;
    }
  }

  .site-nav {
    display: none;
    height: auto;
    background-color: #fff;
  }

  .site-nav .site-nav__list {
    height: auto;
    padding: 0;
    display: block;
  }

  .site-nav .site-nav__list-item {
    float: none;
  }

  .site-nav .site-nav__list > li {
    display: block;
    width: 100%;
  }

  .site-nav .site-nav__list-item:hover .site-nav__link, .site-nav .site-nav__list-item.-selected .site-nav__link {
    background-color: inherit;
  }

  .icon_frontpage {
    height: 105px;
  }
}

@media screen and (max-width: 675px) {
  .site-footer,
  .site-footer__top {
    height: auto;
  }

  .site-footer__top .site-footer__column {
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;

    &.-two {
      margin-top: 40px;

      p {
        font-size: inherit;
      }
    }
  }

  .site-footer__bottom p {
    font-size: 0.8em;
  }
}

@media screen and (max-width: 420px) {
  .contact-form .g-recaptcha {
    -ms-transform: scale(0.77);
    transform: scale(0.77);
    -ms-transform-origin: 50%;
    transform-origin: 50%;
  }

  .icon_frontpage {
    height: 80px;
  }
}


@media screen and (max-width: 351px) {
  .site-footer__bottom p {
    line-height: normal;
    padding-top: 12px;
  }

  .contact-form .g-recaptcha {
    -ms-transform-origin: 30%;
    transform-origin: 30%;
  }
}