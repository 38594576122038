.site-footer {
  height: 223px;

  a.orange-underline {
    color: #a7a7b3;
    position: relative;
    transition: color .2s;

    &:hover {
      color: #fac192;
      text-decoration: none;

      &:after {
        width: 0;
      }
    }

    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #f6a665;
      position: absolute;
      bottom: -3px;
      right: 0;
      transition: width .2s;
    }
  }

  p {
    margin: 3px;
  }
}

.site-footer__top {
  height: 173px;

  .site-footer__column {
    float: left;
    padding: 0 50px;
    margin-top: 6px;

    &.-float-right {
      float: right;
      margin-top: 0;

      p {
        font-size: 1.2rem;
      }

      .tel-no {
        color: #f27e20;
        margin: 0;

        a {
          color: #a7a7b3;
        }
      }
    }
  }
}

.site-footer__bottom {
  height: 50px;
  background-color: #e5e5e5;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  position: relative;

  p {
    color: #a7a7b3;
    line-height: 50px;
    margin: 0;
  }
}