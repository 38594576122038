.site-form {
  .input {
    width: 100%;
    float: left;
    margin-bottom: 15px;

    &.text, .text.textarea {
      text-align: left;
      position: relative;

      label {
        color: #a7a7b3;
        font-size: 1.07143rem;
        cursor: text;
        position: absolute;
        top: 20px;
        width: 78%;
        transition: all .2s;
      }
    }

    &.text input {
      height: 60px;
    }
  }
}

.site-form .input.textarea textarea {
  height: 190px;
  margin: 15px 0 0;
  resize: none;
}

.site-form .input.text input, .site-form .input.textarea textarea, .site-form .input.submit {
  width: 78%;
  padding-top: 12px;
  color: #84859d;
}

.site-form .input.text input, .site-form .input.textarea textarea {
  border: 0;
  border-bottom: 3px solid #fac192;
  line-height: 20px;
}

.site-form .input.text input:focus, .site-form .input.textarea textarea:focus {
  outline: none;
}

.site-form .input.text.-has-value label, .site-form .input.textarea.-has-value label {
  font-size: .85714rem;
  top: 4px;
}

.contact-form .g-recaptcha {
  float: left;
  width: 78%;
  margin: 20px auto;
}

.contact-form .g-recaptcha>div {
  margin: 0 auto;
  position: relative;
}

.contact-form .input.submit {
  width: 78%;
  float: left;
  margin-bottom: 15px;
  color: #84859d;
  padding-top: 10px;
  text-align: center;
}

input.btn {
  color: #84859d;
  background-color: transparent;
  display: inline-block;
  font-size: .92857rem;
  white-space: nowrap;
  vertical-align: top;
  transition: all .2s;
}

input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}


.site-form .input.text label.error, .site-form .input .text.textarea label.error {
  display: block;
  color: red;
  font-size: 1.07143rem;
  position: relative;
  top: 0;
}

.recaptcha-error {
  color: red;
  float: left;
}

.astrix-required {
  color: #f27e20;
}